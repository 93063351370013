/**
 * showSelectStoreModal
 */
function showSelectStoreModal() {
    $('#selectStoreModal').addClass('d-flex');
    $('#selectStoreModal').modal({ keyboard: false });
    $('#btn-noStore').click(() => {
        const url = $('#btn-noStore').data('action');

        $.ajax({
            url,
        }).done(() => {
            // redirect to homepage
            const redirectUrl = $('#btn-noStore').data('redirecturl');
            window.location.href = redirectUrl;
        });
    });
}

/**
 * drawSelectStoreModal
 * Modal Dialog to let shopper select a store
 */
function drawSelectStoreModal(checkout) {
    var url = $('.headerselectstore').data('selectstoremodalurl');
    if (checkout) {
        url = $('.change-shipping-method').data('selectstoremodalurl');
    }

    $.spinner().start();
    if ($('.modalSelectStore').length === 1) {
        $.spinner().stop();
        ActualizeCachedContentSelectStoreModal();
        showSelectStoreModal();
    } else {
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success(data) {
                $('body').append(data.selectStoreModal);
                ActualizeCachedContentSelectStoreModal();
                showSelectStoreModal();
                $.spinner().stop();
            },
        });
    }
}

/**
 * ActualizeCachedContentSelectStoreModal
 * Actualize Modal Dialog to the overwrite the cached info
 */
function ActualizeCachedContentSelectStoreModal() {
    var button = $('.headerselectstore button');
    var buttonText = button.text().trim();
    var miTiendaLabel = buttonText.includes('Mi tienda');

    var closeModalButton = $('.button-close-modal');
    var modalTitleSelect = $('.modal-title-select');
    var modalTitleChange = $('.modal-title-change');

    // st
    if (!miTiendaLabel) {
        closeModalButton.removeClass('d-none');
        modalTitleChange.removeClass('d-none').addClass('d-flex');
        modalTitleSelect.addClass('d-none').removeClass('d-flex');
    } else {
        closeModalButton.addClass('d-none');
        modalTitleChange.addClass('d-none').removeClass('d-flex');
        modalTitleSelect.removeClass('d-none').addClass('d-flex');
    }
}

/**
 * findStore
 * Find store by postalcode
 */
 function findStoreByPostalCode() {
    var url = $('#btn-findByPostalCode').data('action');
    var previousUrl = window.location.href;
    var data = {
        postalcode: $('#select-store-postalcode').val()
    };
    $.spinner().start();
    $.ajax({
        url,
        type: 'get',
        data: data,
        dataType: 'json',
        success(data) {
            if (data.success) {
                $('.no-store-found').addClass('d-none');
                $.spinner().stop();
                //As a URL to delete searchParams
                var urlLink = new URL($('.select-store-popup-link').attr('href'), window.location.origin);
                urlLink.searchParams.delete('id');
                urlLink.searchParams.delete('previousUrl');
                urlLink.searchParams.set('id', data.id);
                urlLink.searchParams.set('previousUrl', previousUrl.toString());
                $('.select-store-popup-link').attr('href', urlLink.toString());
                $('.find-postal-code').removeClass('d-none');
                $('.data-store').html(data.name + ": " + data.address);
                $('.select-store-popup').removeClass('d-none');
            } else {
                $('.no-store-found').removeClass('d-none');
                $('.select-store-popup').addClass('d-none');
                $.spinner().stop();
            }
        },
    });
}

$(document).ready( function () { 
    $(document).on('click','.change-shipping-method', function () { 

        if ($('.modalSelectStore').length === 1){
            ActualizeCachedContentSelectStoreModal();
            showSelectStoreModal();
        } else {
            drawSelectStoreModal(true);
            ActualizeCachedContentSelectStoreModal();
            showSelectStoreModal();
        }

        if ($('.menu-toggleable-left').hasClass('in')) {
            // Close mobile-menu version if active
            // e.preventDefault();
            $('.navbar-nav').find('.top-category').detach();
            $('.navbar-nav').find('.nav-menu').detach();
            $('.navbar-nav').find('.show').removeClass('show');
            $('.menu-toggleable-left').removeClass('in');
    
            $('.main-menu').siblings().attr('aria-hidden', 'false');
            $('header').siblings().attr('aria-hidden', 'false');
    
            $('.modal-background').hide();
            $('.navbar-toggler').show();
        } else {
            // Close all modals to hide desktop modal
            $('.popover').removeClass('show');
        }

    })
    $(document).on('click','.selectStoreModalClose', function () {
        setTimeout(() => {
            $('#selectStoreModal').removeClass('d-flex');
        }, 500);
    })
});

module.exports = {
    init() {
        // Case of storeSelector disfuntion -> verifie the store id in the isml element is OK
        var preferredstoreid = $('.selectStoreHeader').data('preferredstoreid');
        var isPreferredStoreIdValid = typeof preferredstoreid === 'string' && /^[0-9]+$/.test(preferredstoreid);
        // (search-results OR product-detail OR homepage) AND (.headerselectstore se encuentra) AND (.headerselectstore se encuentra con el data)
        if (
            (($('.search-results').length === 1) || ($('.product-detail').length === 1) || ($('#homepage').length === 1))
            && 
            ((($('.headerselectstore').length !== 0) && $('.headerselectstore').data('showstoreselectormodal')) || isPreferredStoreIdValid)
            )
        {
            drawSelectStoreModal();
        }
    },
    showSelectStoreModal(show) {
        //Métodos showSelectStoreModal y showSelectStoreModalCheckout comentados
        // por error de duplicar modal de cambio de tienda
        //if (show) {
        //    showSelectStoreModal();
        //}
    },
    findStoreByPostalcode: function () {
        $('body').on('click', '#btn-findStoreByPostalCode', function (e) {
            $('.find-postal-code').removeClass('d-none');
        });

        $('body').on('click', '#btn-findByPostalCode', function (e) {
            findStoreByPostalCode();
        });
        
        $('body').on('keypress', '#select-store-postalcode', function (e) {
            if (e.which === 13) { //ref ASCII para tecla enter
                e.preventDefault();
                findStoreByPostalCode();
            }
        });
    },
    showSelectStoreModalCheckout: function () {
        //Métodos showSelectStoreModal y showSelectStoreModalCheckout comentados
        // por error de duplicar modal de cambio de tienda
        //$('body').on('click', '.change-shipping-method', function (e) {
        //    drawSelectStoreModal(true);
        //});
    }
};
